/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Merienda:wght@300..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Smooch+Sans:wght@100..900&family=Yuji+Mai&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: "Jost";
 
}
.lendings {
  line-height: 70px !important;
}
body {
  overflow-x: hidden;
  background-color: #010d1f;
}

.gradient-text {
  background: linear-gradient(to right, #22d3ee, #0891b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tree-node {
    position: relative;
    margin-bottom: 40px;
  }
  
  .tree-node img {
    border-radius: 50%;
    border: 2px solid #ddd;
  }
  
  .tree-node .line {
    position: absolute;
    top: 10px;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
  
  .tree-node .children {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .tree-node .children .tree-node {
    margin: 0 20px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #0914469c; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #0914469c; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #4d0000; 
  }
  

  #tradingview-widget-container{
    width: 100% !important;
    border-radius: 8px;
  }

  @keyframes spinSlow {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .animate-spin-slow {
    animation: spinSlow 5s linear infinite;
  }
  

  
  



